export default class Modal {
    constructor() {}

    open(e) {
        e.preventDefault();
        document.body.classList.add('-lock');
        document.body.classList.remove('-gnav_active'); // グロナビが開いていたら閉じる（SP）
        const modalID = this.el.getAttribute('data-modalID');
        const tgtmodal = document.querySelector(`.js-modal[data-modalID=${modalID}]`);
        tgtmodal && tgtmodal.classList.add('-active');
    }

    autoOpen(modalID, src, linkUrl) {
        document.body.classList.add('-lock');
        document.body.classList.remove('-gnav_active');
        const tgtmodal = document.querySelector(`.js-modal[data-modalID=${modalID}]`);
        tgtmodal && tgtmodal.classList.add('-active');

        if (modalID === 'imglink') {
            const imgTag = tgtmodal.querySelector('img');
            imgTag.setAttribute('src', src);

            const aTag = tgtmodal.querySelector('a');
            aTag.setAttribute('href', linkUrl);

        } else if (modalID === 'img') {
            const imgTag = tgtmodal.querySelector('img');
            imgTag.setAttribute('src', src);
        }
    }

    close() {
        document.body.classList.remove('-lock');
        this.modals.forEach((el) => el.classList.remove('-active'));
    }

    eventBind() {
        const openbuttons = [...document.querySelectorAll('.js-modal_open')];
        const closebuttons = [...document.querySelectorAll('.js-modal_close')];
        const modals = [...document.querySelectorAll('.js-modal')];

        openbuttons.forEach((el) => {
            el.addEventListener('click', { el: el, handleEvent: this.open });
        });

        closebuttons.forEach((el) => {
            el.addEventListener('click', { modals: modals, handleEvent: this.close });
        });
    }

    resetEvent() {
        const openbuttons = [...document.querySelectorAll('.js-modal_open')];
        const closebuttons = [...document.querySelectorAll('.js-modal_close')];

        openbuttons.forEach((el) => {
            el.removeEventListener('click', this.open);
        });

        closebuttons.forEach((el) => {
            el.removeEventListener('click', this.close);
        });
    }
}
