import { setSNSWindow } from './functions/setsnswindow.es6';
import { gnav } from './functions/gnav.es6';
import { setIframe } from './functions/set_iframe.es6';

export default class CommonFunction {
    constructor(scrollfunc) {
        this.init(scrollfunc);
    }

    init(scrollfunc) {
        setSNSWindow();
        gnav(scrollfunc);
        setIframe();
    }
}
