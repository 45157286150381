import Modal from './modal.es6';
import ScrollFunc from './scrollfunc.es6';
import CommonFunction from './common_functions.es6';
export default class UI {
    constructor() {
        this.baseDeviceWidth = getComputedStyle(document.documentElement).getPropertyValue('--baseDeviceWidth'); //cssから取得
        this.screenHeight = 0;
        this.modal = new Modal();
        this.scrollfunc = new ScrollFunc();
        this.CommonFunction = new CommonFunction(this.scrollfunc);

        this.init();
    }

    init() {
        //methods
        this.update();
        this.eventBind();
        this.modal.eventBind(); //topでもmodalインスタンスを使うのでメソッドを実行しない限りeventbindしないようにする
    }

    update() {
        this.screenHeight = window.innerHeight;
        document.documentElement.style.setProperty('--screenHeight', `${this.screenHeight}px`);
    }

    eventBind() {
        window.onload = () => {
            document.body.classList.add('-ready');
        };

        let timeoutID = 0;
        let delay = 200;

        window.addEventListener(
            'resize',
            () => {
                clearTimeout(timeoutID);
                timeoutID = setTimeout(() => {
                    this.update();
                }, delay);
            },
            false
        );
    }
}
