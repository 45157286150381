export function setIframe() {
    const videoelm = document.querySelector('.c-articledetail__content iframe');

    if (!videoelm) {
        return;
    }

    const setVideo = () => {
        const w = videoelm.offsetWidth;
        const h = w * 0.5625;
        document.documentElement.style.setProperty('--videoHeight', `${h}px`);
    };

    window.addEventListener('resize', setVideo);
    setVideo();
}
