export function gnav(scrollfunc) {
    const trigger = document.querySelector(".js-gnav_open");
    const openEv = new CustomEvent("gnavOpen");
    const closeEv = new CustomEvent("gnavClose");

    trigger.addEventListener("click", () => {
        //close
        if (document.body.classList.contains("-gnav_active")) {
            document.body.classList.remove("-gnav_active");

            // snswindowが開いていたら閉じる
            closeSnsWindow();

            // closeEv
            window.dispatchEvent(closeEv);
        } else {
            document.body.classList.add("-gnav_active");

            // snswindowが開いていたら閉じる
            closeSnsWindow();

            // Lenisのスクロールを停止
            scrollfunc.lenis && scrollfunc.lenis.stop();
            window.dispatchEvent(openEv);
        }
    });
}

const closeSnsWindow = () => {
    const trigger = [...document.querySelectorAll(".js-snswindow_trigger")];
    const windows = [...document.querySelectorAll(".l-snswindow")];

    trigger.forEach((elm) => {
        elm.classList.remove("-active");
    });
    windows.forEach((elm) => {
        elm.classList.remove("-active");
    });
};
