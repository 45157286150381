import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Lenis from "@studio-freight/lenis";
import ShuffleText from "shuffle-text";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
export default class ScrollFunc {
    constructor() {
        this.baseDeviceWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--baseDeviceWidth"), 10);
        this.lenis = null;
        this.init();
    }

    init() {
        this.initLenis();
        this.shuffleText();
        this.smoothScroll();
        this.onLenisEvent();

        if (document.body.classList.contains('top')) {
            this.fixMv();
            this.headerLogo();
        }
    }

    initLenis() {
        // baseDeviceWidth より大きい場合にLenisを実行
        if (window.innerWidth >= this.baseDeviceWidth) {
            this.lenis = new Lenis({
                lerp: 0.1,
                autoResize: true,
            });

            this.lenis.on("scroll", ScrollTrigger.update);
            gsap.ticker.add((time) => {
                this.lenis.raf(time * 1000);
            });
            gsap.ticker.lagSmoothing(0);
        }
    }

    onLenisEvent() {
        window.addEventListener("gnavOpen", () => {
            if (this.lenis) {
                this.lenis.destroy();
            }
        });

        window.addEventListener("gnavClose", () => {
            this.initLenis();
        });
    }

    shuffleText() {
        gsap.utils.toArray(".js-shuffle").forEach((elm) => {
            const text = new ShuffleText(elm);

            gsap.to(elm, {
                scrollTrigger: {
                    trigger: elm,
                    start: "top bottom",
                    scrub: 1,
                    onEnter: () => {
                        text.start();
                        elm.classList.add("-scrollin");
                    },
                    onLeaveBack: () => {
                        elm.classList.remove("-scrollin");
                    },
                    // markers: true,
                },
            });
        });
    }

    fixMv() {
        // baseDeviceWidth より大きい場合に固定動作を実行
        if (window.innerWidth >= this.baseDeviceWidth) {
            gsap.to(".js-fix", {
                scrollTrigger: {
                    trigger: ".js-fix",
                    start: "top 26px",
                    endTrigger: ".mainvisual__ph",
                    end: "bottom 100%",
                    scrub: 1,
                    pin: true,
                    pinSpacing: false,
                    // markers: true,
                },
            });
        }
    }

    headerLogo() {
        gsap.to(".top .l-header__logowrap", {
            scrollTrigger: {
                trigger: ".mainvisual__ph",
                start: "bottom center",
                scrub: true,
                // markers: true,
                onEnter: () => document.body.classList.add("-showheaderlogo"),
                onLeaveBack: () => document.body.classList.remove("-showheaderlogo"),
            },
        });
    }

    smoothScroll() {
        const triggers = document.querySelectorAll(".js-smoothscroll");

        triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const targetId = el.getAttribute("href");
                const targetElement = document.querySelector(targetId);

                if (targetElement) {
                    // `window.pageYOffset` の代わりに `window.scrollY` を使用
                    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 80;

                    if (this.lenis && !ScrollTrigger.isTouch) {
                        // Lenisを使用してスムーズスクロール
                        this.lenis.scrollTo(targetPosition, { duration: 1 });
                    } else {
                        // GSAPのScrollToPluginを使用してスムーズスクロール
                        gsap.to(window, { duration: 1, scrollTo: targetPosition, ease: "circ.inOut" });
                    }
                }
            });
        });

        // ページトップへのスクロールも同様に実装
        const pagetop = document.querySelectorAll(".js-pagetop");
        pagetop.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                if (this.lenis && !ScrollTrigger.isTouch) {
                    this.lenis.scrollTo(0, { duration: 1 });
                } else {
                    gsap.to(window, { duration: 1, scrollTo: 0, ease: "circ.inOut" });
                }
            });
        });
    }
}
